import Form, { formCreate } from '@/found/components/form';
import request from '@/found/utils/request';
import ruleList from './components/rule_list.vue';
import ProductList from './components/product_list.vue';
import BusinessOrganization from './components/business_organization.vue';

formCreate.component('ruleList', ruleList);
formCreate.component('ProductList', ProductList);
formCreate.component('BusinessOrganization', BusinessOrganization);

export default {
  extends: Form,
  components: {
    // 挂载组件
    formCreate: formCreate.$form(),
  },
  data() {
    return {
      formFunctionCode: 'main_form',
      formParentCode: process.env.VUE_APP_BASE_API === 'https://crm1.xifenggroup.com/' ? 'CRM20231215000000003' : 'CRM20231205000000003',
    };
  },
  methods: {
    // 表单渲染完成后调用
    async formComplete() {
      if (this.formConfig.code === 'view' || this.formConfig.code === 'edit') {
        const params = {};
        let url = '';
        // 规则明细
        if (this.formConfig.type === 'detail') {
          url = '/tpm/tpmRewardPoolRule/findByDetailId';
          params.detailId = this.formConfig.row.id;
          this.disabled = true;
        } else {
          url = '/tpm/tpmRewardPoolRule/findById';
          params.id = this.formConfig.row.id;
        }
        await request.get(url, params).then((res) => {
          if (res.success) {
            if (res.result.rewardRuleType === '3') {
              this.hiddenFields(true, 'rewardChannels');
              this.hiddenFields(true, 'orgName');
            }
            setTimeout(() => {
              this.setValue({
                ...res.result,
                orgName: res.result.orgVos || [],
              });
            }, 500);
          }
        });
      } else {
        // 设置默认值
        this.setValue({
          policyVos: [],
        });
      }
    },
    setRule(item) {
      const v = item;
      if (v.field === 'productName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'product_list',
            paramData: {
              enableStatus: '009',
            },
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const {
              materialName, materialCode, productLevelName, productLevelCode,
            } = e[0];
            this.setValue({
              productName: materialName || '',
              productCode: materialCode || '',
              productSeriesDesc: productLevelName || '',
              productSeriesCode: productLevelCode || '',
            });
          },
        };
      } else if (v.field === 'orgName') {
        v.props = {
          ...v.props,
          params: {
            functionCode: 'business_organization_list',
          },
          getValue: (field) => this.getFieldValue(field),
          title: v.title,
          autosize: { minRows: 1, maxRows: 6 },
        };
        v.on = {
          ...v.on,
          change: (e) => {
            const arr = [...e];
            const orgVos = [];
            arr.forEach((m) => {
              orgVos.push({
                organizationName: m.orgName,
                organizationCode: m.orgCode,
                orgType: m.orgType,
                orgName: m.orgName,
                orgCode: m.orgCode,
              });
            });
            this.setValue({
              orgVos: orgVos || [],
              orgName: orgVos || [],
            });
          },
        };
      } else if (v.field === 'beginDate' || v.field === 'endDate') {
        v.props = {
          ...v.props,
          relation: {
            beginField: 'beginDate',
            endField: 'endDate',
          },
        };
      } else if (v.field === 'policyVos') {
        v.props = {
          ...v.props,
          getFieldValue: this.getFieldValue,
          code: this.formConfig.code,
        };
      } else if (v.field === 'rewardRuleType') {
        v.on = {
          ...v.on,
          change: (e) => {
            if (e === '直营扫码奖励') {
              this.hiddenFields(true, 'rewardChannels');
              this.hiddenFields(true, 'orgName');
              this.setValue({
                rewardChannels: null,
                orgVos: null,
                orgName: null,
              });
            } else {
              this.hiddenFields(false, 'rewardChannels');
              this.hiddenFields(false, 'orgName');
            }
            this.setValue({
              policyVos: [],
            });
          },
        };
      }
      return v;
    },
    // 提交表单
    submit() {
      const formData = this.getFormData();
      if (!formData) {
        return false;
      }
      delete formData.T1;
      delete formData.T2;
      delete formData.T3;
      delete formData.T4;
      const params = { ...formData };
      let url = '';
      if (this.formConfig.code === 'add') {
        url = '/tpm/tpmRewardPoolRule/create';
      } else {
        url = '/tpm/tpmRewardPoolRule/update';
        params.id = this.formConfig.row.id;
      }
      request.post(url, params).then((res) => {
        if (res.success) {
          this.$message.success(res.message);
          this.$emit('onClose');
          this.$emit('onGetList');
        }
      });
    },
  },
};
