var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      !_vm.disabled
        ? _c("vxe-toolbar", {
            scopedSlots: _vm._u(
              [
                {
                  key: "buttons",
                  fn: function () {
                    return [
                      _c(
                        "el-link",
                        {
                          attrs: { type: "primary", icon: "el-icon-plus" },
                          on: { click: _vm.addTable },
                        },
                        [_vm._v("新增")]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              2948762210
            ),
          })
        : _vm._e(),
      _c(
        "vxe-table",
        { ref: "xTable", attrs: { data: _vm.tableData } },
        [
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "rewardAction",
              title: "奖励动作",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择奖励动作",
                          disabled: _vm.disabled,
                        },
                        on: {
                          change: function ($event) {
                            return _vm.rewardActionChange(rowIndex)
                          },
                        },
                        model: {
                          value: row.rewardAction,
                          callback: function ($$v) {
                            _vm.$set(row, "rewardAction", $$v)
                          },
                          expression: "row.rewardAction",
                        },
                      },
                      _vm._l(_vm.rewardAction, function (item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "rewardObject",
              title: "奖励对象",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  var rowIndex = ref.rowIndex
                  return [
                    _c(
                      "el-select",
                      {
                        attrs: {
                          placeholder: "请选择奖励对象",
                          disabled: _vm.disabled,
                        },
                        on: {
                          focus: function ($event) {
                            return _vm.rewardObjectClick(row, rowIndex)
                          },
                        },
                        model: {
                          value: row.rewardObject,
                          callback: function ($$v) {
                            _vm.$set(row, "rewardObject", $$v)
                          },
                          expression: "row.rewardObject",
                        },
                      },
                      _vm._l(_vm.rewardObject, function (item) {
                        return _c("el-option", {
                          key: item.dictCode,
                          attrs: {
                            label: item.dictValue,
                            value: item.dictCode,
                          },
                        })
                      }),
                      1
                    ),
                  ]
                },
              },
            ]),
          }),
          _c("vxe-table-column", {
            attrs: {
              "min-width": "100",
              field: "rewardAmount",
              title: "奖励金额",
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function (ref) {
                  var row = ref.row
                  return [
                    _c("el-input-number", {
                      attrs: {
                        label: "奖励金额",
                        step: 0.0001,
                        precision: 4,
                        disabled: _vm.disabled,
                      },
                      model: {
                        value: row.rewardAmount,
                        callback: function ($$v) {
                          _vm.$set(row, "rewardAmount", $$v)
                        },
                        expression: "row.rewardAmount",
                      },
                    }),
                  ]
                },
              },
            ]),
          }),
          !_vm.disabled
            ? _c("vxe-table-column", {
                attrs: {
                  fixed: "right",
                  title: "操作",
                  align: "center",
                  width: "80",
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "default",
                      fn: function (ref) {
                        var rowIndex = ref.rowIndex
                        return [
                          _c("el-button", {
                            staticClass: "text-red",
                            attrs: {
                              slot: "reference",
                              type: "text",
                              icon: "el-icon-delete",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.deleteRule(rowIndex)
                              },
                            },
                            slot: "reference",
                          }),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2284881827
                ),
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }