<template>
 <div>
    <vxe-toolbar v-if="!disabled">
      <template v-slot:buttons>
        <el-link type="primary" icon="el-icon-plus" @click="addTable">新增</el-link>
      </template>
    </vxe-toolbar>
    <vxe-table  ref="xTable" :data="tableData">
      <vxe-table-column min-width="100" field="rewardAction" title="奖励动作">
          <template #default="{ row,rowIndex }">
            <el-select v-model="row.rewardAction" placeholder="请选择奖励动作" :disabled="disabled" @change="rewardActionChange(rowIndex)">
                <el-option
                v-for="item in rewardAction"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode">
                </el-option>
            </el-select>
          </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="rewardObject" title="奖励对象">
         <template #default="{ row,rowIndex }">
            <el-select v-model="row.rewardObject" placeholder="请选择奖励对象" :disabled="disabled" @focus="rewardObjectClick(row,rowIndex)">
                <el-option
                v-for="item in rewardObject"
                :key="item.dictCode"
                :label="item.dictValue"
                :value="item.dictCode">
                </el-option>
            </el-select>
          </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="rewardAmount" title="奖励金额">
         <template #default="{ row }">
             <el-input-number v-model="row.rewardAmount" label="奖励金额" :step="0.0001" :precision="4" :disabled="disabled"></el-input-number>
          </template>
      </vxe-table-column>
      <vxe-table-column fixed="right" title="操作" align="center" width="80" v-if="!disabled">
          <template #default="{ rowIndex }">
            <el-button class="text-red" slot="reference"  type="text"  icon="el-icon-delete" @click="deleteRule(rowIndex)"></el-button>
          </template>
      </vxe-table-column>
    </vxe-table>
</div>
</template>

<script>
import request from '@/found/utils/request';

export default {
  props: {
    value: Array,
    disabled: Boolean,
    getFieldValue: Function,
    code: String,
  },
  data() {
    return {
      tableData: [],
      rewardAction: [], // 奖励动作
      rewardObject: [], // 奖励对象
    };
  },
  watch: {
    value() {
      this.tableData = this.value;
    },
  },
  async created() {
    await this.getDictSelect();
  },
  methods: {
    // 新增行
    addTable() {
      const rewardRuleType = this.getFieldValue('rewardRuleType');
      if (rewardRuleType === '1') {
        this.rewardAction = this.reward_action.filter((v) => v.dictCode === '4');
        this.rewardObject = this.reward_object.filter((v) => v.dictCode === 'consumer');
      } else if (rewardRuleType === '2') {
        this.rewardAction = this.reward_action;
      } else if (rewardRuleType === '3') {
        this.rewardAction = this.reward_action.filter((v) => v.dictCode === '3' || v.dictCode === '4');
      } else {
        return this.$message.error('请选择规则类型');
      }
      this.tableData.push({
        rewardAction: '',
        rewardObject: '',
        rewardAmount: 0,
      });
    },
    // 删除规则
    deleteRule(index) {
      this.tableData.splice(index, 1);
    },
    // 获取数据字典
    getDictSelect() {
      const params = [
        'reward_action',
        'tpm_reward_object',
      ];
      request.post('/mdm/mdmdictdata/batchDictSelect', params).then((res) => {
        if (res.success) {
          if (this.code === 'add') {
            this.reward_action = res.result.reward_action;
            this.reward_object = res.result.tpm_reward_object;
          } else {
            this.rewardAction = res.result.reward_action;
            this.rewardObject = res.result.tpm_reward_object;
          }
        }
      });
    },
    // 奖励动作切换
    rewardActionChange(rowIndex) {
      this.tableData[rowIndex].rewardObject = '';
    },
    // 过滤动作对应奖励对象
    rewardObjectClick(row, rowIndex) {
      if (this.getFieldValue('rewardRuleType') !== '1') {
        if (row.rewardAction === '1' || row.rewardAction === '2') {
          this.rewardObject = this.reward_object.filter((v) => v.dictCode === 'dealer');
        } else if (row.rewardAction === '3' || row.rewardAction === '4') {
          this.rewardObject = this.reward_object.filter((v) => v.dictCode !== 'consumer');
        } else {
          this.rewardObject = [];
          return this.$message.error(`请先选择第${rowIndex + 1}行奖励动作`);
        }
      }
    },
  },
};
</script>
